@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import '~application/node_modules/foundation-sites/scss/foundation';

.error-404 {
  .logo {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 135px;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
  }

  h1 {
    font-weight: 900;
    text-transform: uppercase;
    margin: 2.5rem 0;
  }

  .error-log {
    margin-top: 2.5rem;

    .content-box {
      font-family: 'Courier New', Courier, monospace;
    }
  }

  @include breakpoint(600px) {
    .logo {
      width: 200px;
    }

    h1 {
      font-size: 3rem;
    }
  }
}
